import React from "react";
import CPButton from "./Utils/CPButton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import _ from "lodash";
import Button from "@mui/material/Button";
import { authContext } from "../contexts/AuthContext";
import { Box } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import AddIcon from "@mui/icons-material/Add";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { withTranslation } from "react-i18next";
import CPDeleteIconButton from "./Utils/CPDeleteIconButton";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import Chip from "@mui/material/Chip";
import "react-block-ui/style.css";
import { Grid } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { formatDate } from "../Utils/Format";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CPText from "./Utils/CPText";
import CPTip from "./Utils/CPTip";
import { createEvent } from "ics";
import { saveAs } from "file-saver";
import {
  listAlertsByDocId,
  listSuggestionsByClientId,
  insertAlert,
} from "../services/alerts";

class Alerts extends React.Component {
  constructor() {
    super();

    this.state = {
      valueFechaAdd: null,
      alertList: [],
      suggestions: [],
      emails: [],
      addEmail: "",
      sendToValue: "",
    };
  }

  static contextType = authContext;

  componentDidMount() {
    this.fetchSuggestions();
    this.fetchAlerts();
  }

  handleAddEmail = () => {
    let re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!this.state.addEmail.match(re)) {
      return;
    }

    let emails = this.state.emails;
    emails.push({ email: this.state.addEmail });
    this.setState({
      emails: emails,
      addEmail: "",
    });
  };

  handleAddSuggestion = (suggestion) => {
    let emails = this.state.emails;
    emails.push(suggestion);
    this.setState({
      emails: emails,
    });
  };

  handleDeleteEmail = (email) => {
    let emails = this.state.emails;
    _.remove(emails, (item) => {
      return _.isEqual(item, email);
    });
    this.setState({
      emails: emails,
    });
  };

  fetchAlerts = async () => {
    let list = await listAlertsByDocId(this.props.docId);
    this.setState({ alertList: list });
  };

  fetchSuggestions = async () => {
    let list = await listSuggestionsByClientId(this.props.clientId);
    this.setState({ suggestions: list });
  };

  insertAlert = async () => {
    let month = this.state.valueFechaAdd.getMonth() + 1;
    let monthStr = month < 10 ? `0${month}` : month;
    let day = this.state.valueFechaAdd.getDate();
    let dayStr = day < 10 ? `0${day}` : day;
    let expiration = `${this.state.valueFechaAdd.getFullYear()}-${monthStr}-${dayStr}`;

    let params = {
      document_id: this.props.docId,
      expiration: expiration,
      emails: this.state.emails.map((email) => email.email),
      externalemails: document.getElementById("email_textfield").value,
      message: document.getElementById("message_field").value,
    };

    await insertAlert(params);
    this.setState({ valueFechaAdd: null, emails: [] }, () =>
      this.fetchAlerts()
    );
  };

  deleteAlert = async (id) => {
    let params = {
      id: id,
    };

    let url =
      "https://blpclientportalfunctions.azurewebsites.net/api/DeleteAlert?code=I19tsxhi7Hy0bn2ZftuLWZ-4xr-vUTncq-v_94BoNs1PAzFuMdukBA==";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(params),
    })
      .then((res) => console.log(res))
      .then((success) => {
        let list = this.state.alertList;
        _.remove(list, (alert) => {
          return alert.id === id;
        });

        this.setState({ alertList: list });

        console.log("Delete successful");
      })
      .catch((error) => console.log(error));
  };

  toggleSuggestion = (event, user) => {
    let emails = this.state.emails;

    if (event.target.checked) {
      emails.push(user);
    } else {
      _.remove(emails, (u) => {
        return u.id == user.id;
      });
    }

    this.setState({
      emails: emails,
    });
  };

  downloadICS = (alert) => {
    const { t } = this.props;

    let d = new Date(alert.expiration);

    const event = {
      start: [d.getFullYear(), d.getMonth() + 1, d.getDate(), 0, 0],
      duration: { hours: 24 },
      title: "Client Portal Alert",
      description: `${t("Alert")} ${alert.message ? "- " + alert.message : ""}`,
      organizer: { name: `${window.$brand} Client Portal` },
      busyStatus: "FREE",
    };

    createEvent(event, (error, value) => {
      const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
      saveAs(blob, `Client Portal Alert.ics`);
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Dialog
        open={!_.isEmpty(this.props.alerts)}
        onClose={this.props.closeHandler}
      >
        <DialogTitle>{t("Alerts")}</DialogTitle>
        <DialogContent>
          {this.state.alertList.map((alert, idx) => (
            <Container sx={{ p: 2, width: 400 }} key={idx}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Paper elevation={3} sx={{ p: 2, width: "100%" }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <CPText
                        label={t("Date")}
                        text={formatDate(t, alert.expiration)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CPText label={t("Emails")} />
                      {alert.emails &&
                        alert.emails
                          .split(";")
                          .map((email) => (
                            <Chip key={email} label={email}></Chip>
                          ))}
                    </Grid>
                    <Grid item xs={12}>
                      <CPText label={t("Message")} text={alert.message} />
                    </Grid>
                    <Grid item xs={12}>
                      <CPButton
                        icon={<FileDownloadOutlinedIcon />}
                        tooltip={t("Download to Outlook")}
                        onClick={() => {
                          this.downloadICS(alert);
                        }}
                      />
                      <CPDeleteIconButton
                        onClick={() => this.deleteAlert(alert.id)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Container>
          ))}
          <Button
            sx={{
              backgroundColor: window.$brand === "PRB" ? "#17afb6" : "#102a46",
              borderRadius: window.$brand === "PRB" ? 0 : 5,
            }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => this.setState({ valueFechaAdd: new Date() })}
          >
            {t("Add_alert")}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeHandler}>{t("Close")}</Button>
        </DialogActions>
        <Dialog
          open={this.state.valueFechaAdd !== null}
          onClose={() => this.setState({ valueFechaAdd: null })}
        >
          <DialogTitle>{t("Add_alert")}</DialogTitle>
          <DialogContent>
            <CPTip text={t("Add_alert_tip")} />
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                mt: 2,
                width: "400px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("Date")}
                  value={this.state.valueFechaAdd}
                  onChange={(newValue) => {
                    this.setState({
                      valueFechaAdd: newValue.$d,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end", mb: 2, mt: 2 }}>
              <Autocomplete
                sx={{ width: "100%" }}
                multiple
                options={this.state.suggestions}
                disableCloseOnSelect
                disableClearable
                getOptionLabel={(u) =>
                  u.id !== undefined
                    ? u.first_name + " " + u.last_name
                    : u.email
                }
                value={
                  this.state.suggestions
                    .filter((u) =>
                      _.find(this.state.emails, (e) => e.id === u.id)
                    )
                    .concat(
                      this.state.emails.filter((u) => u.id === undefined)
                    ) || null
                }
                ChipProps={{
                  onDelete: null,
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      onChange={(e) => this.toggleSuggestion(e, option)}
                      checked={_.find(
                        this.state.emails,
                        (e) => e.id === option.id
                      )}
                    />
                    <ListItemAvatar>
                      {option.image_url ? (
                        <Avatar src={option.image_url} />
                      ) : (
                        <Avatar>{option.first_name[0]}</Avatar>
                      )}
                    </ListItemAvatar>
                    {option.first_name +
                      " " +
                      option.last_name +
                      " | " +
                      option.email}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline
                    value={this.state.sendToValue}
                    label="Send to:"
                  />
                )}
              />
            </Box>
            <Box>
            <TextField
                id="email_textfield"              
                label="External emails(Use commas to separate independent emails)"
                fullWidth
                minRows={1}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                mt: 2,
                width: "400px",
              }}
            >
              
              <TextField
                id="message_field"
                multiline
                label="Message"
                fullWidth
                minRows={6}
              />
            </Box>


          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ valueFechaAdd: null })}>
              {t("Cancel")}
            </Button>
            <Button
              disabled={_.isEmpty(this.state.emails)}
              onClick={() => {
                this.insertAlert();
              }}
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  }
}

export default withTranslation()(Alerts);
